import React, { useState } from "react";

import "./tab.bootstrap.scss";

/**
 * Represents a tab item
 * @typedef {Object} TabItem
 * @property {string} name - The name of the tab, displayed on the tab container.
 * @property {React.JSX.Element} data - The content of the tab, displayed on the page.
 */

/**
 * Displays a set of tabs, and underneath, the content within them.
 *
 * @param {TabItem[]} tabs - An array of the tabs to be displayed. Works best with 4 or less.
 * @param {number} defaultActiveTabIndex - Which tab should be focused when the component loads. Default 0.
 * @returns {React.JSX.Element}
 */
const Tab = ({ tabs, defaultActiveTabIndex }) => {
  let [activeTab, setActiveTab] = useState(defaultActiveTabIndex);

  function handleTab(tab) {
    activeTab = !setActiveTab;
    setActiveTab(tab);
  }

  const onTabEnter = ({ key }, tab) => {
    if (key === "Enter" && tab !== undefined) {
      handleTab(tab);
    }
  };

  const getMobileTabWidth = () => {
    const numberOfTabs = tabs.length;

    switch (numberOfTabs) {
      case 1:
        return "col-12";
      case 2:
        return "col-6";
      case 3:
        return "col-4";
      case 4:
        return "col-3";
      default:
        return "col-3";
    }
  };

  return (
    <>
      {/* Desktop Display */}
      <section className="d-none d-md-block" id="tab-desktop">
        <div className="bg-blue-10 mb-4 border-bottom">
          <div className="container">
            <div className="row no-gutters">
              {tabs.map(({ name }, i) => (
                <div
                  id={`tab${i + 1}-lg`}
                  key={`tab-${i + 1}`}
                  className={`col-sm-6 col-md-3 py-2 custom-tab ${activeTab === i ? "custom-active-tab" : ""}`}
                  onClick={() => handleTab(i)}
                  onKeyDown={(e) => onTabEnter(e, i)}
                  tabIndex={0}
                >
                  <div className="font-weight-bold text-center text-gray-70">{name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="container">{tabs[activeTab].data}</div>
      </section>
      {/* Mobile Display */}
      <section id="tab-mobile" className="d-block d-md-none container">
        <div className="sticky-top bg-white mb-4">
          <div className="row">
            {tabs.map(({ name }, i) => (
              <div
                id={`tab${i + 1}-xs`}
                key={`tab-${i + 1}-xs`}
                className={`${getMobileTabWidth()} py-2 custom-tab ${activeTab === i ? "custom-active-tab" : ""}`}
                onClick={() => handleTab(i)}
              >
                <div className="text-center">{name}</div>
              </div>
            ))}
          </div>
        </div>
        {tabs[activeTab].data}
      </section>
    </>
  );
};
export default Tab;

Tab.defaultProps = {
  tabs: [
    {
      name: "",
      data: <></>
    },
    {
      name: "",
      data: <></>
    }
  ],
  defaultActiveTabIndex: 0
};
