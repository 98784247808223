import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import AboutUsNavigation from "../../components/about-us/about-us-navigation";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import Tab from "../../components/tab/custom-tab";

const CommunityMeetingRooms = () => {
  const title = "Community Meeting Rooms";
  const description = "WaFd Bank offers Community Meeting Rooms at several of our branch locations.";
  const imgData = useStaticQuery(graphql`
    query {
      ashland2: file(relativePath: { eq: "branches/community-meeting-rooms/meeting-rooms-or-ashland-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      ashland3: file(relativePath: { eq: "branches/community-meeting-rooms/meeting-rooms-or-ashland-3.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      ashland4: file(relativePath: { eq: "branches/community-meeting-rooms/meeting-rooms-or-ashland-4.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      burlington2: file(relativePath: { eq: "branches/community-meeting-rooms/meeting-rooms-wa-burlington-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      burlington3: file(relativePath: { eq: "branches/community-meeting-rooms/meeting-rooms-wa-burlington-3.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      saltLakeCity2: file(relativePath: { eq: "branches/community-meeting-rooms/meeting-rooms-ut-plaza-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      saltLakeCity3: file(relativePath: { eq: "branches/community-meeting-rooms/meeting-rooms-ut-plaza-3.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      klamathFalls2: file(
        relativePath: { eq: "branches/community-meeting-rooms/meeting-rooms-or-klamath-falls-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      klamathFalls3: file(
        relativePath: { eq: "branches/community-meeting-rooms/meeting-rooms-or-klamath-falls-3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/community-meeting-rooms"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-community-meeting-room-041723.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: true,
      title: "Community Meeting Rooms"
    }
  ];

  const branchData = [
    {
      branchName: "Ashland",
      state: "Oregon",
      phoneNumber: "541-488-6977",
      callOptionAvailable: true,
      address: "175 Lithia Way, Suite 100, Ashland, OR 97520",
      addressUrl:
        "https://www.google.com/maps/place/Washington+Federal+Bank/@42.1971059,-122.7124822,15z/data=!4m5!3m4!1s0x0:0xe4124b8f628e76d4!8m2!3d42.1971059!4d-122.7124822",
      imageUrls: [
        imgData.ashland2.childImageSharp.gatsbyImageData,
        imgData.ashland3.childImageSharp.gatsbyImageData,
        imgData.ashland4.childImageSharp.gatsbyImageData
      ]
    },
    {
      branchName: "Burlington",
      state: "Washington",
      phoneNumber: "360-755-9241",
      callOptionAvailable: true,
      address: "300 E Fairhaven Ave, Burlington, WA 98233",
      addressUrl:
        "https://www.google.com/maps/place/Washington+Federal+Bank/@48.4753246,-122.3340942,17z/data=!4m13!1m7!3m6!1s0x54856da299cd6b01:0xc2e4dc210407162b!2s300+E+Fairhaven+Ave,+Burlington,+WA+98233!3b1!8m2!3d48.4753246!4d-122.3319055!3m4!1s0x54856da299c3b377:0x36f8bd58e4833dab!8m2!3d48.475317!4d-122.331942",
      imageUrls: [
        imgData.burlington2.childImageSharp.gatsbyImageData,
        imgData.burlington3.childImageSharp.gatsbyImageData
      ]
    },
    {
      branchName: "Klamath Falls",
      state: "Oregon",
      phoneNumber: "541-885-4400",
      callOptionAvailable: false,
      address: "5215 South Sixth St, Klamath Falls, OR 97603",
      addressUrl:
        "https://www.google.com/maps/place/Washington+Federal+Bank/@42.2070103,-121.7271206,17z/data=!3m1!4b1!4m5!3m4!1s0x54c8d59ba9c6c0eb:0xec497e5a42f9628e!8m2!3d42.2070103!4d-121.7251555",
      description:
        "Conference Center: The facility contains three rooms for hosting meetings or conferences. Groups or individuals wishing to use the Conference Center facility may make arrangements by contacting Windermere Real Estate, at <a id='windermere-real-estate-tel-link' href='tel:541-885-4400'>541-885-4400</a> or <a id='windermere-real-estate-mail-link' href='mailto:ayork@windermere.com'>ayork@windermere.com.</a>",
      imageUrls: [
        imgData.klamathFalls2.childImageSharp.gatsbyImageData,
        imgData.klamathFalls3.childImageSharp.gatsbyImageData
      ]
    },
    {
      branchName: "Salt Lake City",
      state: "Utah",
      phoneNumber: "801-366-2238",
      callOptionAvailable: true,
      address: "405 South Main St, Suite 100, Salt Lake City, UT 84111",
      addressUrl:
        "https://www.google.com/maps/place/Washington+Federal+Bank/@40.7602438,-111.8911956,18.82z/data=!4m13!1m7!3m6!1s0x8752f510f6ff7bc1:0x775d4cccb8b8e0f2!2s405+S+Main+St+%23100,+Salt+Lake+City,+UT+84111!3b1!8m2!3d40.7601835!4d-111.8907206!3m4!1s0x8752f56d8df1338b:0x538f3eb2dfcc0a12!8m2!3d40.7600753!4d-111.8907838",
      imageUrls: [
        imgData.saltLakeCity2.childImageSharp.gatsbyImageData,
        imgData.saltLakeCity3.childImageSharp.gatsbyImageData
      ]
    }
  ];

  const TabData = ({ data, index }) => (
    <>
      <h2 className="text-success">
        {data.branchName}, {data.state}
      </h2>
      <p>
        <a id={`branch-address-${index + 1}`} target="_blank" rel="noopener noreferrer" href={data.addressUrl}>
          {data.address}
        </a>
      </p>
      {data.callOptionAvailable && (
        <p>
          Call to book:{" "}
          <a id={`branch-call-to-book-${index + 1}`} href={`tel:${data.phoneNumber}`}>
            {data.phoneNumber}
          </a>
        </p>
      )}
      {data.description && <p dangerouslySetInnerHTML={{ __html: data.description }}></p>}

      <div className="row">
        {data.imageUrls.map((imageData, index, imageArray) => {
          const imagesNeedLgMargin = imageArray.length > 2;
          const isLastElement = imageArray.length - 1 === index;
          return (
            <div
              className={`col-12 col-md-auto d-flex justify-content-center ${
                isLastElement ? "" : `mb-3 ${imagesNeedLgMargin ? "mb-xl-0" : "mb-lg-0"}`
              }`}
            >
              <GatsbyImage
                style={{ maxWidth: "350px" }}
                image={imageData}
                alt={`${data.branchName}, ${data.state} meeting rooms`}
              />
            </div>
          );
        })}
      </div>
    </>
  );

  const tabComponentData = {
    tabs: [
      { name: "Ashland, OR", data: <TabData data={branchData[0]} index={0} /> },
      { name: "Klamath Falls, OR", data: <TabData data={branchData[2]} index={2} /> },
      { name: "Burlington, WA", data: <TabData data={branchData[1]} index={1} /> },
      { name: "Salt Lake City, UT", data: <TabData data={branchData[3]} index={3} /> }
    ],
    defaultActiveTabIndex: 0
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <AboutUsNavigation />
      <section className="container pb-0">
        <h1>Community Meeting Rooms</h1>
        <h4>
          As part of WaFd Bank&rsquo;s desire to give back to the communities we serve, in several of our branch
          locations we offer up community meeting spaces. If you have a need for a conference room and your meeting is
          of a business nature, give us a call. Last year we hosted over 2,000 meetings in our local communities.
        </h4>
        <h4 className="mb-0">To book space please contact the local branches at the numbers below:</h4>
      </section>
      <Tab {...tabComponentData} />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default CommunityMeetingRooms;
